/*
--- 01 TYPOGRAPHY SYSTEM

- Font Sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font Weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line Heights
Default: 1
Small: 1.05
Paragraph default: 1.6

--- 02 COLORS

-> SEE :root color definitions

- GRAYS
GRAY1: #f1f3f5
GRAY3: #dee2e6
GRAY5: #adb5bd

--- 03 IMAGES

--- 04 ICONS

--- 05 SHADOWS

--- 06 BORDER-RADIUS

Default: 8px;
Contact Us: 30px;

--- 07 WHITESPACE

- Spacings (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

:root {
  --primary-color: #FFFE89;
  --primary-dark-color: #e5e47b;
  --secondary-color: #A486D5;
  --secondary-dark-color: #9378BF;
  --accent-color: #B3FFF7;
  --accent-dark-color: #a1e5de;
  --text-color: #48515A;
  --text-dark-color: #2A3439;
  --neg-text-color: #FFFFFF;
  --whatsapp-color: #25D366;
  --whatsapp-dark-color: #21bd5b;
}

* {
  font-family: "Inter", sans-serif;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h3 {
  font-family: Oxanium, sans-serif;
  color: var(--text-dark-color);
}

.container-app {
  background-color: var(--color-background);
  overflow: auto;
}

.container-main {
  margin: 24px 0;
}

.container-content-fixed {
  padding: 0 128px;
}

.container-image {
  display: flex;
  justify-content: center;
}

.header {
  margin-top: 44px;
  margin-bottom: 24px;
}

.whatsapp-icon {
  fill: var(--neg-text-color);
  height: 30px;
  width: 30px;
}

.empty-container {
  display: flex;
  width: 100%;
  height: 50vw;
  justify-content: center;
  align-items: center;
}

.card-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 14vw;
}

.scroll-to-top-btn {
  position: fixed;  
   right: 5%; 
   bottom: 2rem; 
   font-size: 3rem; 
   z-index: 1; 
   cursor: pointer;
}

.scroll-to-top-btn-grp svg {
  grid-area: 1 / 1;
}

.scroll-to-top-icon {
  fill: var(--secondary-color);
}

.scroll-to-top-btn-grp:hover .scroll-to-top-icon {
  fill: var(--secondary-dark-color);
}

/* 
  This is to overwrite bootstrap styling for cards
*/
.row>* {
  padding-right: 0;
  padding-left: 0;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #bbb; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}

@media only screen and (max-width: 1920px) {
  .card-image {
    height: 12vw;
  }
}
@media only screen and (max-width: 1400px) {
  .container-content-fixed {
    padding: 0 64px;
  }
  .card-image {
    height: 16vw;
  }
}
@media only screen and (max-width: 1200px) {
  .container-content-fixed {
    padding: 0 64px;
  }
  .card-image {
    height: 20vw;
  }
}
@media only screen and (max-width: 992px) {
  .container-content-fixed {
    padding: 0 32px;
  }
  .card-image {
    height: 22vw;
  }
}
@media only screen and (max-width: 768px) {
  .container-content-fixed {
    padding: 0 32px;
  }
  .card-image {
    height: 36vw;
  }
}
@media only screen and (max-width: 576px) {
  .container-content-fixed {
    padding: 0 24px;
  }
  .card-image {
    height: 36vw;
  }
}

/******************************************************************************
  ================================== ALERT ===================================
******************************************************************************/

.alert-main-container {
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 8%;
}

.alert-button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .alert-main-container {
    padding: 0 6%;
  }
}

@media only screen and (max-width: 992px) {
  .alert-main-container {
    padding: 0 4%;
  }
  .alert-custom-widget {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .alert-main-container {
    padding: 0;
  }
  .alert-custom-widget {
    margin-bottom: 0;
  }
}

/******************************************************************************
  ================================ PAGINATION ================================
******************************************************************************/

.page-link {
  color: var(--text-color);
}

.page-link:link {
  color: var(--text-color);
}

.page-link:visited {
  color: var(--text-color);
}

.page-link:hover {
  color: var(--text-color);
}

.page-link:active {
  color: var(--text-color);
}

.active>.page-link, .page-link.active {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--neg-text-color);
}

/******************************************************************************
  ================================== BORDER ==================================
******************************************************************************/

.border-custom-primary {
  border-style: solid;
  border-color: var(--primary-color) !important;
  border-width: medium !important;
}

.border-custom-secondary {
  border-style: solid;
  border-color: var(--secondary-color) !important;
  border-width: medium !important;
}

/******************************************************************************
  ==================================== BG ====================================
******************************************************************************/

.bg-custom-primary {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.bg-custom-secondary {
  background-color: var(--secondary-color);
}

/******************************************************************************
  ================================= BUTTONS ==================================
******************************************************************************/

/* ===== btn-custom-primary ===== */

.btn-custom-primary {
  background-color: var(--primary-color);
  color: var(--text-color);
  font-weight: 500;
}

.btn-custom-primary:hover {
  background-color: var(--primary-dark-color);
  color: var(--text-color);
}

.btn-custom-primary:active {
  background-color: transparent !important;
  color: var(--primary-dark-color) !important;
  border-color: var(--primary-dark-color) !important;
}

/* ===== btn-custom-secondary ===== */

.btn-custom-secondary {
  background-color: var(--secondary-color);
  color: var(--neg-text-color);
  font-weight: 500;
}

.btn-custom-secondary:hover {
  background-color: var(--secondary-dark-color);
  color: var(--neg-text-color);
}

.btn-custom-secondary:active {
  background-color: transparent !important;
  color: var(--secondary-dark-color) !important;
  border-color: var(--secondary-dark-color) !important;
}

/* ===== btn-custom-accent ===== */

.btn-custom-accent {
  background-color: var(--accent-color);
  color: var(--text-color);
  font-weight: 500;
}

.btn-custom-accent:hover {
  background-color: var(--accent-dark-color);
  color: var(--text-color);
}

.btn-custom-accent:active {
  background-color: transparent !important;
  color: var(--accent-dark-color) !important;
  border-color: var(--accent-dark-color) !important;
}

/* ===== btn-whatsapp ===== */

.btn-whatsapp {
  display: inline-block;
  background-color: var(--whatsapp-color);
  text-decoration: none;
  border-radius: 30px;
  padding: 0.5rem 0.75rem;
  width: 180px;
  margin: auto;
}

.btn-whatsapp:hover {
  background-color: var(--whatsapp-dark-color);
}

.btn-whatsapp-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--neg-text-color);
  font-weight: 500;
}

/******************************************************************************
  ================================== NAVBAR ==================================
******************************************************************************/

.nav-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 128px;
  margin-top: 16px;
}

.nav-logo {
  height: 50px;
}

.navbar-container {
  margin-right: 0;
}

.navbar-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-bottom: 0;
  padding-left: 0;
}

.navbar-item {
  list-style: none;
}

.navbar-item a:link {
  color: var(--text-color);
  text-decoration: none;
}

.navbar-item a:visited {
  color: var(--text-color);
  text-decoration: none;
}

.navbar-item a:hover {
  color: var(--text-color);
  text-decoration: none;
}

.navbar-item a:active {
  color: var(--text-color);
  text-decoration: none;
}

.navbar-item--7 a:link {
  color: var(--neg-text-color);
}

.navbar-item--7 a:visited {
  color: var(--neg-text-color);
}

.navbar-item--7 a:hover {
  color: var(--neg-text-color);
}

.navbar-item--7 a:active {
  color: var(--neg-text-color);
}

@media only screen and (max-width: 1366px) {
  .nav-main-container {
    padding: 0 64px;
  }
}
@media only screen and (max-width: 992px) {
  .nav-main-container {
    padding: 0 32px;
  }
}
@media only screen and (max-width: 768px) {
  .nav-main-container {
    padding: 0 32px;
  }
}
@media only screen and (max-width: 576px) {
  .nav-main-container {
    padding: 0 24px;
  }
}

/******************************************************************************
  ================================== FOOTER ==================================
******************************************************************************/

.footer-main-container {
  background-color: #B3FFF7;
  padding: 24px 128px;
  margin-top: 80px;
}

.footer-sub-container {
  margin: 0 10%;
}

.footer-text {
  text-align: center;
  color: var(--text-dark-color);
}

.footer-text--1 {
  font-weight: 600;
  opacity: 60%;
  margin-top: 32px;
  letter-spacing: 2px;
}

.footer-text--2 {
  font-family: Oxanium, sans-serif;
  font-weight: 700;
  font-size: calc(0.5em + 4.5vmin);
  margin-top: 24px;
}

.footer-text--3 {
  margin: 16px 288px 0 288px;
}

.footer-text--4 {
  margin-top: 32px;
}

.footer-text--5 {
  margin-top: 16px;
  font-size: 0.8em;
}

.footer-text--5 a {
  color: var(--text-color);
  text-decoration: none;
}

.footer-text--5 a:hover {
  text-decoration: underline;
}

.footer-text--6 {
  margin-top: 32px;
  font-size: 0.8em;
}

.footer-horiz-line {
  color: var(--text-dark-color);
  background-color: var(--text-dark-color);
  opacity: 20%;
  height: 1px;
  border-width:0;
  margin: 32px 0;
}

.footer-nav-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.footer-navbar-list {
  gap: 48px;
}

.footer-nav-main-item--1 {
  display: inline;
  vertical-align: middle;
}

.footer-logo {
  height: 15px;
}

.footer-nav-main-item--2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.socials-container {
  margin-right: 0;
}

.socials-list {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 0;
  padding-left: 0;
}

.socials-item {
  list-style: none;
}

.socials-item a:link {
  text-decoration: none;
}

.socials-item a:visited {
  text-decoration: none;
}

.socials-item a:hover {
  text-decoration: none;
}

.socials-item a:active {
  text-decoration: none;
}

.socials-icon {
  color: var(--text-color);
}

@media only screen and (max-width: 1366px) {
  .footer-main-container {
    padding: 12px 64px;
  }
  .footer-text--3 {
    margin: 16px 128px 0 128px;
  }
  .footer-nav-main-item--1 {
    align-self: flex-end;
  }
  .footer-nav-main-item--3 {
    align-self: flex-end;
  }
  .footer-navbar-list {
    flex-direction: column;
    gap: 24px;
  }
  .socials-list {
    gap: 24px;
  }
}
@media only screen and (max-width: 992px) {
  .footer-sub-container {
    margin: 0 2.5%;
  }
  .footer-text--3 {
    margin: 16px 64px 0 64px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-nav-main-container {
    flex-direction: column;
    gap: 24px;
  }
  .footer-nav-main-item--1 {
    align-self: center;
    order: 3;
  }
  .footer-nav-main-item--3 {
    align-self: center;
  }
  .footer-text--3 {
    margin: 16px 32px 0 32px;
  }
}
@media only screen and (max-width: 576px) {
  .footer-main-container {
    padding: 6px 32px;
  }
}

/******************************************************************************
  ========================== 404 PAGE CLASSES BELOW ==========================
******************************************************************************/

.page-not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.startled-img {
  max-width: 30%;
  height: auto;
}

@media only screen and (max-width: 1920px) {
  .startled-img {
  max-width: 40%;
  }
}
@media only screen and (max-width: 1366px) {
  .startled-img {
  max-width: 60%;
  }
}
@media only screen and (max-width: 992px) {
  .startled-img {
  max-width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .startled-img {
  max-width: 100%;
  }
}

.page-not-found-btn-grp {
  display: flex;
  gap: 8px;
}

/******************************************************************************
  ========================= HOME PAGE CLASSES BELOW ==========================
******************************************************************************/

.home-banner {
  margin-top: 52px;
  width: 100%;
  height: auto;
  padding: 8vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('images/home/Home-Banner.png') no-repeat;
  background-size: cover;
}

.home-banner-text {
  font-size: calc(0.5em + 5vmin);
  text-align: center;
  text-shadow: 3px 3px var(--primary-color);
}

.home-banner-btn {
  margin-top: 24px;
  font-size: 1rem;
}

.home-main-container {
  margin-top: 48px;
}

.home-partner {
  margin-top: 48px;
}

@media only screen and (max-width: 992px) {
  .home-partner-text {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .home-partner {
    padding: 0 1rem;
  }
}
.color-blocks {
  width: 150px;
  height: 100px;
}

.color-blocks--1 {
  background-color: var(--secondary-color);
}

.color-blocks--2 {
  background-color: var(--primary-color);
  margin-top: -50px;
  margin-left: 50px;
  display: flex;
	align-items: center;
  justify-content: center;
}

.color-blocks--2 p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 1rem;
}

.home-partner-paragraph {
  margin-top: 48px;
}

.home-partner-email a {
  color: var(--text-color);
  text-decoration: none;
}

.home-partner-email a:hover {
  text-decoration: underline;
}

/******************************************************************************
  ======================= COURSES PAGE CLASSES BELOW ========================
******************************************************************************/

.tags-badge {
  cursor: pointer;
}

.tags-cross-icon, .tags-plus-icon {
  stroke-width: 1;
}

.tags-cross-icon path {
  color: var(--neg-text-color);
}

.courses-hdr-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.courses-translate-btn {
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 16px;
}

.courses-translate-icn {
  font-size: 2em;
}

.courses-translate-lang {
  font-size: 1.5em;
}

.class-card-header {
  margin: 12px;
}

.class-card-body {
  flex: 1 1 auto;
}

.course-title-grp {
  display: flex;
  justify-content: space-between;
}

.card-title {
  font-weight: 600;
  font-size: 1.15rem;
}

.course-like-icon {
  cursor: pointer;
}

.course-like-icon svg {
  fill: #ea868f;
}

.liked-pop-toast {
  width: 14rem;
  margin-right: 0.5rem;
}

.course-card-tag-badge {
  cursor: pointer;
}

.horz-line {
  border-bottom: 2px solid;
  width: 100%;
  color: #dee2e6;
}

.card-footer-btn-container {
  display: flex;
  flex-direction: column;
}

.class-enquire-btn {
  margin-top: 1rem;
  text-align: center;
}

.class-icn {
  vertical-align: unset;
}

.card-text {
  margin-bottom: 8px;
}

.pagination-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}

/******************************************************************************
  ======================= ABOUT US PAGE CLASSES BELOW ========================
******************************************************************************/

.abt-us-header {
  padding: 0 128px;
}

.abt-us-hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: url('images/about-us/About-Banner.png') no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 33rem;
}

.abt-us-hero-header {
  color: var(--neg-text-color);
  font-weight: 700;
  font-size: calc(0.5em + 5vmin);
  text-shadow: 3px 3px var(--secondary-color);
}

.abt-us-hero-text {
  width: 55%;
  color: var(--neg-text-color);
  font-size: calc(0.5em + 1.75vmin);
  text-align: center;
  text-shadow: 3px 3px var(--text-color);
}

.abt-us-who-container {
  margin-top: 48px;
  padding: 0 4rem;
  text-align: center;
}

.abt-us-who-header {
  letter-spacing: 2px;
}

.abt-us-who-text {
  margin-top: 16px;
}

.abt-us-tri-blocks {
  margin-top: 32px;
  margin-right: 96px;
  padding: 24px 24px 0px 0px;
  display: flex;
}

.abt-us-block {
  padding: 64px;
  width: 33%;
}

.abt-us-block:nth-child(odd) {
  background-color: #adb5bd;
}

.abt-us-block:nth-child(even) {
  background-color: #dee2e6;
}

.abt-us-icon {
  align-self: flex-start;
  position: relative;
  top: -0.5em;
  height: 4em;
  width: 4em;
}

.abt-us-block-header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 32px;
}

.abt-us-block-header h3 {
  margin-left: 16px;
}

.vert-line {
  border-left: 12px solid;
  height: 14vh;
  margin-right: 12px;
}

.abt-us-block h3 {
  font-weight: 600;
  align-self: flex-end;
  margin-bottom: 0;
}

.abt-us-block--1 h3, .abt-us-block--1 .vert-line, .abt-us-block--1 .abt-us-icon {
  color: var(--primary-color);
  fill: var(--primary-color);
}

.abt-us-block--2 h3, .abt-us-block--2 .vert-line, .abt-us-block--2 .abt-us-icon {
  color: var(--secondary-color);
  fill: var(--secondary-color);
}

.abt-us-block--3 h3, .abt-us-block--3 .vert-line, .abt-us-block--3 .abt-us-icon {
  color: var(--accent-color);
  fill: var(--accent-color);
}

.abt-us-partners {
  margin: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.abt-us-partner-logo {
  transform: scale(0.8);
}

@media only screen and (max-width: 1366px) {
  .abt-us-header {
    padding: 0 64px;
  }
  .abt-us-hero-container {
    height: 30rem;
  }
  .abt-us-tri-blocks {
    flex-direction: column;
  }
  .abt-us-block {
    width: 98%;
  }
  .abt-us-partner-logo {
    transform: scale(0.6);
  }
}
@media only screen and (max-width: 992px) {
  .abt-us-header {
    padding: 0 32px;
  }
  .abt-us-hero-container {
    height: 28rem;
  }
  .abt-us-partners {
    flex-direction: column;
    margin: 0;
  }
  .abt-us-partner-logo--1 {
    margin: 3rem 0;
  }
}
@media only screen and (max-width: 768px) {
  .abt-us-header {
    padding: 0 32px;
  }
  .abt-us-hero-container {
    height: 25rem;
  }
  .abt-us-tri-blocks {
    margin-right: 16px;
  }
  .abt-us-block {
    padding: 48px;
  }
}
@media only screen and (max-width: 576px) {
  .abt-us-header {
    padding: 0 24px;
  }
  .abt-us-hero-container {
    height: 22rem;
  }
  .abt-us-partner-logo {
    transform: scale(0.5);
  }
}
/******************************************************************************
  ========================= TEAM PAGE CLASSES BELOW ==========================
******************************************************************************/

.trainer-img {
  height: 10vw;
  width: 10vw;
  margin-top: 20px;
  margin-bottom: 20px;
}

.team-biscuit {
  display: flex;
	align-items: center;
  margin: 64px 192px 0 192px;
}

.team-biscuit-left {
  margin-right: 48px;
}

.team-biscuit-left--heading {
  font-weight: 700;
  width: 55%;
}

.biscuit-horiz-line {
  color: var(--secondary-color);
  background-color: var(--secondary-color);
  opacity: 60%;
  height: 3px;
  border-width:0;
  margin: 16px 0;
  width: 60%;
}

.team-biscuit-img {
  width: auto;
  height: 380px;
}

@media only screen and (max-width: 1400px) {
  .trainer-img {
    height: 12vw;
    width: 12vw;
  }
  .team-biscuit {
    margin: 64px 96px 0 96px;
  }
  .team-biscuit-img {
    width: auto;
    height: 300px;
  }
}
@media only screen and (max-width: 1200px) {
  .trainer-img {
    height: 14vw;
    width: 14vw;
  }
}
@media only screen and (max-width: 992px) {
  .trainer-img {
    height: 16vw;
    width: 16vw;
  }
  .team-biscuit {
    flex-direction: column-reverse;
  }
  .team-biscuit-left {
    margin-top: 48px;
  }
}
@media only screen and (max-width: 768px) {
  .trainer-img {
    height: 18vw;
    width: 18vw;
  }
  .team-biscuit {
    margin: 32px 48px 0 48px;
  }
  .team-biscuit-left--heading {
    width: 80%
  }
}
@media only screen and (max-width: 576px) {
  .trainer-img {
    height: 24vw;
    width: 24vw;
  }
  .team-biscuit {
    margin: 32px 24px 0 24px;
  }
  .team-biscuit-img {
    width: auto;
    height: 200px;
  }
  .team-biscuit-left--heading {
    width: 90%
  }
}
@media only screen and (max-width: 425px) {
  .trainer-img {
    height: 32vw;
    width: 32vw;
  }
}

/******************************************************************************
  ====================== RESOURCES PAGE CLASSES BELOW ========================
******************************************************************************/

/* .resource-ul {
  list-style-type: none;
} */

/* .resource-fa-li {
  margin:0 5px 0 -15px;
} */

/******************************************************************************
  ========================= FAQ PAGE CLASSES BELOW ===========================
******************************************************************************/

.faq-img {
  max-width: 50%;
  height: auto;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 1920px) {
  .faq-img {
  max-width: 40%;
  }
}
@media only screen and (max-width: 1366px) {
  .faq-img {
  max-width: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .faq-img {
  max-width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .faq-img {
  max-width: 70%;
  }
}

.accordion-button {
  font-weight: 500;
  font-size: 0.8em;
}

.accordion-button:not(.collapsed) {
  background-color: var(--secondary-color);
  color: var(--neg-text-color);
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}