/* Inter - Regular */
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/* Inter - Medium  */
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./fonts/Inter/Inter-Medium.ttf) format("truetype");
    font-style:normal;
    font-weight: 500;
}

/* Inter - SemiBold  */
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./fonts/Inter/Inter-SemiBold.ttf) format("truetype");
    font-style:normal;
    font-weight: 600;
}

/* Oxanium - Regular */
@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
    url(./fonts/Oxanium/Oxanium-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/* Oxanium - Medium */
@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
    url(./fonts/Oxanium/Oxanium-Medium.ttf) format("truetype");
    font-style: normal;
    font-weight: 500;
}

/* Oxanium - SemiBold */
@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
    url(./fonts/Oxanium/Oxanium-SemiBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 600;
}

/* Oxanium - Bold */
@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
    url(./fonts/Oxanium/Oxanium-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-background: #fafafa;
  --color-foreground: #1f2023;
  --color-shadow: #aaa;

  --image-grayscale: 0;
  --image-opacity: 100%;

  background-color: var(--color-background);
}

body.dark {
  --color-background: #1f2023;
  --color-foreground: #efefef;
  --color-shadow: rgba(0, 0, 0, 0.8);

  --image-grayscale: 50%;
  --image-opacity: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img,
video {
  filter: grayscale(var(--image-grayscale)) opacity(var(--image-opacity));
}
